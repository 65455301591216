import React from 'react'

const CartItemsSummary = ({ formattedCart, removeItemFromCart, subtotal }) => {
  return formattedCart.length > 0 ? (
    <div className="mt-3">
      <p className="text-center">Subtotal: ${(subtotal / 100).toFixed(2)}</p>
      <ul className="list-group mb-3">
        {formattedCart.map((item, i) => {
          return (
            <li key={i} className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h5 className="my-0">
                  {item.quantity > 1 && (
                    <strong className="font-weight-bold">{item.quantity}X</strong>
                  )}{' '}
                  {item.name.split('|')[0]}
                </h5>
                {item.selectedVariety.name !== 'default' && (
                  <div>
                    <small className="text-muted">
                      {item.varietyName}: {item.selectedVariety.name}
                    </small>
                    <br />
                  </div>
                )}
                {item.selectedSize !== 'default' && (
                  <div>
                    <small className="text-muted">
                      {item.sizeName}: {item.selectedSize}
                    </small>
                    <br />
                  </div>
                )}
                {item.specialInstructions && (
                  <small className="text-muted">Instructions: {item.specialInstructions}</small>
                )}
                <p
                  style={{ marginTop: '5px', fontSize: '0.9rem', cursor: 'pointer', color: 'red' }}
                  onClick={() => {
                    removeItemFromCart(item.id)
                  }}
                >
                  Remove Item
                </p>
              </div>
              <span className="text-muted">
                ${((item.quantity * item.unitPrice) / 100).toFixed(2)}
              </span>
            </li>
          )
        })}
      </ul>
    </div>
  ) : null
}

export default CartItemsSummary
