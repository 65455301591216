import React, { createContext, useReducer, useContext } from 'react'

const CartContext = createContext()

const INITIAL_STATE = {
  items: [],
  selectedRestaurant: null,
  search: { address: '', restaurants: [] },
  formattedCart: [],
  appSettings: null,
}

function cartReducer(state, { type, payload }) {
  switch (type) {
    case 'CLEAR_ITEMS': {
      localStorage.setItem('cart', JSON.stringify([]))
      return { ...state, items: [] }
    }
    case 'SET_ITEMS': {
      localStorage.setItem('cart', JSON.stringify(payload))
      return { ...state, items: payload }
    }
    case 'ADD_ITEM': {
      const cartItems = state.items
      const newItem = { ...payload }
      cartItems.push(newItem)
      localStorage.setItem('cart', JSON.stringify(cartItems))
      return { ...state, items: cartItems }
    }
    case 'REMOVE_ITEM': {
      const cartItems = state.items.filter((item) => item.id !== payload)
      localStorage.setItem('cart', JSON.stringify(cartItems))
      return { ...state, items: cartItems }
    }
    case 'SET_SELECTED_RESTAURANT':
      return { ...state, selectedRestaurant: payload }
    case 'SET_SEARCHED_STORES':
      return {
        ...state,
        search: {
          address: payload.address,
          stores: payload.stores,
          lat: payload.lat,
          long: payload.long,
        },
      }
    case 'SET_FORMATTED_CART':
      return { ...state, formattedCart: payload }
    case 'SET_APP_SETTINGS':
      return { ...state, appSettings: payload }
    default: {
      throw new Error(`Unhandled action type: ${type}`)
    }
  }
}

function CartProvider({ children }) {
  const [state, dispatch] = useReducer(cartReducer, INITIAL_STATE)
  return <CartContext.Provider value={{ state, dispatch }}>{children}</CartContext.Provider>
}

function useCart() {
  const context = useContext(CartContext)

  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider')
  }

  return context
}

export { CartProvider, useCart }
