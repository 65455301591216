import React, { useEffect } from 'react'
import axios from 'axios'

import Spinner from '../components/common/Spinner'

const RegisterTapfoodCallback = ({ history }) => {
  useEffect(() => {
    const url = window.location.href

    axios
      .post('https://api.tapapps.us/register/tapfood/callback', { url })
      .then(({ data }) => {
        if (data.status === 'ok') {
          history.push('/register/thank-you')
        }
      })
      .catch(err => {
        console.error(err.response.data)
        window.location.href = '/'
      })
  }, [history])

  return <Spinner />
}

export default RegisterTapfoodCallback
