import React from 'react'
import { Link } from 'react-router-dom'

const RestaurantListCard = ({ store }) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="card shadow p-3 h-100">
        <div className="d-flex justify-content-between">
          <span>{store.isNew && <b>NEW!</b>}</span>
          <div>
            {store.deliveryByTap ? (
              <span aria-label="Delivery by taplocal" role="img" style={{ fontSize: '1.7rem' }}>
                🚗
              </span>
            ) : (
              <span aria-label="Delivery not by taplocal" role="img" style={{ fontSize: '1.7rem' }}>
                🚙
              </span>
            )}
            {store.distanceInMeters < store.deliveryRadius && (
              <span
                aria-label="store is close to you"
                role="img"
                style={{ fontSize: '1.4rem', marginLeft: '4px' }}
              >
                ✔️
              </span>
            )}
          </div>
        </div>
        <Link to={`/${store.nickname}`}>
          <div className="text-center hovergray">
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/tap-restaurant-1.appspot.com/o/localLogos%2F${store.logo}?alt=media&token=7d1b6e24-f80b-4841-b9a3-6582d146adff`}
              alt={store.name}
              className="card-img-top img-thumbnail h-100 mt-3"
              style={{ width: '70%' }}
            />
          </div>
        </Link>
        <div className="card-body mt-4 text-center">
          <h4 className="card-title font-weight-bold">{store.name}</h4>
          <p className="card-text"> {'$'.repeat(store.dollars)}</p>
        </div>
        <ul className="list-group list-group-flush text-center">
          {store.description
            .split(',')
            .slice(0, 2)
            .map((item, i) => (
              <li key={i} className="list-group-item">
                {item}
              </li>
            ))}
          <li className="list-group-item">
            {(store.distanceInMeters * 0.000621371192).toFixed(1)} mi (
            {(store.distanceInMeters / 1000).toFixed(1)}
            km) away
          </li>
          <li className="list-group-item">
            {store.isOpen === 0 && <span style={{ color: 'red' }}>Closed</span>}
            {store.isOpen === 1 && <span style={{ color: '#e26b16' }}>Closing Soon!</span>}
            {store.isOpen === 2 && <span style={{ color: 'green' }}>Open</span>}
          </li>
        </ul>
        <div className="card-body text-center">
          <Link to={`/${store.nickname}`}>
            <button className="btn btn-primary">Order Now!</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default RestaurantListCard
