import React from 'react'
import { Link } from 'react-router-dom'

import TapfoodLogo from '../assets/images/main-logo.png'

const RegisterTapfoodThankYou = ({ match }) => {
  return (
    <div className="register" style={{ paddingTop: '80px' }}>
      <div className="logo">
        <img className="" src={TapfoodLogo} alt="Logo" />
      </div>
      <div className="content">
        <h1>Thank you for joining tapfood!</h1>
        <p>We are very happy to have you onboard.</p>
        <Link to="/">
          <button
            style={{
              padding: '10px 30px',
              fontSize: '1.5rem',
              backgroundColor: '#e34d00',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
            }}
          >
            Back to restaurants
          </button>
        </Link>
      </div>
    </div>
  )
}

export default RegisterTapfoodThankYou
