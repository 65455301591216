import React from 'react'
import { Link } from 'react-router-dom'
import MainLogo from '../../assets/images/main-logo.png'
import AppleIcon from '../../assets/images/svg/apple-icon.svg'
import { useCart } from '../../CartContext'

const Header = (props) => {
  const { state } = useCart()

  return (
    <div className={props.blackTheme ? 'theme-black' : ''}>
      <header className="header">
        <div className="container clearfix">
          <div className="logo-wrap">
            <div className="hide-on-small-screens main-logo text-center">
              <Link to="/">
                <img src={MainLogo} id="main-logo" alt="taplocal logo" style={{ width: '140px' }} />
              </Link>
            </div>

            <div className="navmenu2 d-flex align-items-center justify-content-center pt-4">
              <Link className="mr-3" to="/">
                Home
              </Link>
              <Link className="mr-3" to="/stores">
                Restaurants
              </Link>

              <Link className="mr-3" to="/cart">
                Cart
              </Link>
              <span
                onClick={(e) => {
                  e.preventDefault()

                  window.open('https://apps.apple.com/us/app/taplocal/id1488953753', '_blank')
                }}
                className="ml-3 cursor-hover get-app btn btn-radial btn-primary btn-sm btn-icon-right"
              >
                <img src={AppleIcon} alt="Get iPhone app" />
                Get App
              </span>
            </div>
          </div>
          <div className="header-navigation">
            <div className="header-navigation-inner d-flex align-items-center">
              <div className="navigation">
                <ul className="main-nav">
                  <li className="nav-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/stores">Stores</Link>
                  </li>
                  {state.selectedRestaurant && (
                    <li className="nav-item">
                      <Link to={`/${state.selectedRestaurant.nickname}`}>
                        {state.selectedRestaurant.name.slice(0, 22) + '...'}
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
              <div className="header-cta text-right d-flex align-items-center justify-content-end">
                <Link to="/cart" className="cart-icon">
                  View Cart ({state.items.length})
                </Link>
                <span
                  onClick={(e) => {
                    e.preventDefault()

                    window.open('https://apps.apple.com/us/app/taplocal/id1488953753', '_blank')
                  }}
                  className="ml-3 cursor-hover get-app btn btn-radial btn-primary btn-sm btn-icon-right"
                >
                  <img src={AppleIcon} alt="Get iPhone app" />
                  Get App
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Header
