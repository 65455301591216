import React from 'react'

const CustomerDetailsForm = ({
  doingDeliveries,
  orderDetails,
  setOrderDetails,
  deliveryString,
  deliveryOnly,
}) => {
  return (
    <>
      <div className="text-center">
        <h4 style={{ fontSize: '2rem', fontWeight: 'bold', color: '#000' }}>
          {!deliveryOnly ? 'Choose pickup or delivery' : 'Pickup Only'}
        </h4>
        <div className="radio mt-4">
          {!deliveryOnly && (
            <label>
              <input
                type="radio"
                value="Pickup"
                checked={orderDetails.deliveryMethod === 'Pickup'}
                onChange={(e) =>
                  setOrderDetails({
                    ...orderDetails,
                    deliveryMethod: 'Pickup',
                  })
                }
              />{' '}
              <span style={{ fontSize: '1.25rem' }}>Pickup</span>
            </label>
          )}
          {doingDeliveries ? (
            <label>
              <input
                type="radio"
                value="Delivery"
                checked={orderDetails.deliveryMethod === 'Delivery'}
                onChange={(e) =>
                  setOrderDetails({
                    ...orderDetails,
                    deliveryMethod: 'Delivery',
                  })
                }
              />{' '}
              <span style={{ fontSize: '1.25rem' }}>Delivery</span>
            </label>
          ) : (
            <>
              <p style={{ marginTop: '20px', fontSize: '1.4rem', color: '#000' }}>
                Delivery not available at this time.
              </p>
              <p style={{ fontSize: '1.2rem', color: '#000' }}>{deliveryString}</p>
            </>
          )}
        </div>
      </div>
      <br />
      <div className="mt-2">
        <div className="row">
          <div className="col-6">
            <label htmlFor="customerName">Your Name</label>
            <input
              value={orderDetails.fullName}
              onChange={(e) => setOrderDetails({ ...orderDetails, fullName: e.target.value })}
              type="text"
              className="form-control"
            />
          </div>
          <div className="col-6">
            <label htmlFor="customerPhone">Your Phone #</label>
            <input
              value={orderDetails.phone}
              onChange={(e) => setOrderDetails({ ...orderDetails, phone: e.target.value })}
              type="text"
              className="form-control"
            />
          </div>
        </div>
      </div>
      <br />
      {orderDetails.deliveryMethod === 'Delivery' && (
        <div className="mt-2">
          <div className="row">
            <div className="col-12">
              <>
                <label htmlFor="customerAddress">Full Delivery Address</label>
                <input
                  value={orderDetails.address}
                  onChange={(e) =>
                    setOrderDetails({
                      ...orderDetails,
                      address: e.target.value,
                    })
                  }
                  type="text"
                  className="form-control"
                />
              </>
            </div>
          </div>
          <br />
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <>
            <label htmlFor="notes">Special or Delivery Instructions</label>
            <input
              value={orderDetails.notes}
              onChange={(e) =>
                setOrderDetails({
                  ...orderDetails,
                  notes: e.target.value,
                })
              }
              type="text"
              className="form-control"
            />
          </>
        </div>
      </div>
    </>
  )
}

export default CustomerDetailsForm
