import axios from 'axios'
import { getStore } from './restaurant-helpers'

// When the application starts up, check localstorage and hydrate data
export async function initializeApp() {
  try {
    console.log('Initializing app...')

    localStorage.removeItem('selected-section')

    const results = {
      cartItems: [],
      selectedStore: null,
      appSettings: null,
    }

    const storedCart = JSON.parse(localStorage.getItem('cart'))
    const storedStoreNickname = localStorage.getItem('selectedStore')

    if (!storedCart) {
      localStorage.setItem('cart', JSON.stringify([]))
    } else {
      if (storedCart.length > 0) {
        results.cartItems = storedCart
      }
    }

    if (storedStoreNickname) {
      console.log(`Loading ${storedStoreNickname}...`)
      const store = await getStore(storedStoreNickname)
      results.selectedStore = store
    }

    const { data } = await axios.get('https://api.tapapps.us/app-settings-local')

    if (data.status === 'ok') {
      results.appSettings = data.appSettings
    }

    return results
  } catch (err) {
    throw err
  }
}

// Transform a location into coordinates and address
export async function geocodeLocation(location) {
  try {
    const { data } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: {
        address: location,
        key: 'AIzaSyB_Br1zcP_pGVd5qkiiy1A5b3oj-dbbDV4',
      },
    })

    const lat = data.results[0].geometry.location.lat
    const long = data.results[0].geometry.location.lng
    const address = data.results[0].formatted_address

    return { lat, long, address }
  } catch (err) {
    throw err
  }
}

export async function sendMarketingEmail(email) {
  try {
    await axios.post(`https://api.tapapps.us/website/taplocal/marketing-email`, { email })
  } catch (err) {
    throw err
  }
}
