import React, { useEffect, useState } from 'react'
import Header from './Header'

const BaseLayout = ({ page, title = 'taplocal', children }) => {
  const [blackTheme, setBlackTheme] = useState(false)

  useEffect(() => {
    document.title = title

    if (page !== 'Home') {
      setBlackTheme(true)
    }
  }, [title, page])

  return (
    <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <Header blackTheme={blackTheme} />
      <main className="main-content" style={{ flex: 1 }}>
        {children}
      </main>
      <footer className="text-center p-2" style={{ fontSize: '0.78rem' }}>
        &copy; {new Date().getFullYear()} taplocal | <a href="sms:+13475746132">(347) 574-6132</a> |{' '}
        <a href="mailto:support@taplocal.store">support@taplocal.store</a> | Built with ❤️ by{' '}
        <a href="https://zachweinberg.com" target="_blank" rel="noopener noreferrer">
          Zach
        </a>
      </footer>
    </div>
  )
}

export default BaseLayout
