import React, { useEffect } from 'react'
import axios from 'axios'

import Spinner from '../components/common/Spinner'

const RegisterTaplocalCallback = ({ history }) => {
  useEffect(() => {
    const url = window.location.href

    axios
      .post('https://api.tapapps.us/register/taplocal/callback', { url })
      .then(({ data }) => {
        if (data.status === 'ok') {
          history.push('/register/taplocal/thank-you')
        }
      })
      .catch(err => {
        console.error(err.response.data)
        window.location.href = '/'
      })
  }, [history])

  return <Spinner />
}

export default RegisterTaplocalCallback
