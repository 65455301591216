import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import FallBackImage from '../../assets/images/main-logo.png'

const MenuItemListCard = ({ restaurant, item, onSelectItem, history }) => {
  return (
    <div className="col-lg-3 col-md-6">
      <div className="box box-ab-content">
        <div className="box-img-item bg d-flex align-items-center justify-content-center edge-shadow-two">
          <img
            style={{ cursor: 'pointer', width: '100%' }}
            className="food-item"
            src={`https://firebasestorage.googleapis.com/v0/b/tap-restaurant-1.appspot.com/o/itemImages%2F${item.thumbImage}?alt=media&token=7d1b6e24-f80b-4841-b9a3-6582d146adff`}
            alt={item.name}
            onClick={() => history.push(`/item/${item.id}`)}
            onError={(e) => {
              e.target.onerror = null
              e.target.src = FallBackImage
            }}
          />
        </div>

        <div className="box-content bg-light-gray">
          <h4 className="text-center">{item.name.split('|')[0]}</h4>
          <p className="gray-ex mb-0 mb-4">{item.description}</p>
          <p
            style={{ color: 'rgb(48,68,79)', fontSize: '1.6rem', fontWeight: '700' }}
            className="mb-0 mb-4"
          >
            {item.price === 0
              ? item.picker.price
                ? `$${(Number(item.picker.price[0]) / 100).toFixed(2)}`
                : ''
              : `$${(Number(item.price) / 100).toFixed(2)}`}
          </p>
          <Link to={`/item/${item.id}`}>
            <button className="btn btn-primary">View item</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default withRouter(MenuItemListCard)
