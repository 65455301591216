import React, { useState } from 'react'
import axios from 'axios'
import BaseLayout from '../components/layout/BaseLayout'

const Jobs = () => {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    location: '',
  })

  const onSubmit = async (e) => {
    e.preventDefault()

    await axios.post('https://api.tapapps.us/website/job-application', {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      location: form.location,
    })

    alert('Thank you! We got your application and will be in touch.')

    setForm({ firstName: '', lastName: '', email: '', location: '' })
  }

  return (
    <BaseLayout page="Jobs" title="taplocal | Jobs">
      <section className="block secondary-bg mt-5">
        <div className="container">
          <div className="row">
            <div className="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8 offset-md-2 col-md-8 text-center">
              <p>
                Thank you for applying to be a driver for tapfood! Driving for us is a great way to
                make some side cash, and we have a simple process:
              </p>
              <ul className="my-4">
                <li>- An order comes in for a restaurant</li>
                <li>- You get an automated text with the information and address to deliver to</li>
                <li>- Within our driver app, you mark the order as yours</li>
                <li>- You deliver the order, keep most of the delivery fee and 100% of tips</li>
              </ul>
              <p>
                If you would like to proceed, please apply below and we will contact you. Thank you!
              </p>
              <form className="mt-4" onSubmit={onSubmit}>
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    required
                    value={form.firstName}
                    onChange={(e) => setForm({ ...form, firstName: e.target.value })}
                    type="text"
                    className="form-control"
                    placeholder="Enter first name"
                  />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    required
                    value={form.lastName}
                    onChange={(e) => setForm({ ...form, lastName: e.target.value })}
                    type="text"
                    className="form-control"
                    placeholder="Enter last name"
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    required
                    value={form.email}
                    onChange={(e) => setForm({ ...form, email: e.target.value })}
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                  />
                </div>
                <div className="form-group">
                  <label>What part of NJ do you live in?</label>
                  <input
                    required
                    value={form.location}
                    onChange={(e) => setForm({ ...form, location: e.target.value })}
                    type="text"
                    className="form-control"
                    placeholder="Enter location"
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit Application
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </BaseLayout>
  )
}

export default Jobs
