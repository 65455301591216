import React from 'react'
import BaseLayout from '../../components/layout/BaseLayout'
import RestaurantListCard from './RestaurantListCard'
import { Redirect } from 'react-router-dom'
import { useCart } from '../../CartContext'
import { getNearbyStores } from '../../helpers/restaurant-helpers'
import { toast } from 'react-toastify'

const RestaurantListing = () => {
  const { state, dispatch } = useCart()

  const getFilteredStoresByCategory = async (cat) => {
    try {
      const stores = await getNearbyStores(state.search.lat, state.search.long, null, cat)

      if (!stores || stores.length === 0) {
        alert('No category found within these nearby stores!')
        return
      }

      dispatch({
        type: 'SET_SEARCHED_STORES',
        payload: {
          address: state.search.address,
          lat: state.search.lat,
          long: state.search.long,
          stores,
        },
      })
    } catch (err) {
      console.error(err)
      toast.error(`Sorry, there are no stores with that category near you`, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  return !state.search.stores ? (
    <Redirect to="/" />
  ) : state.search.stores.length === 0 ? (
    <Redirect to="/" />
  ) : (
    <BaseLayout page="Found restaurants" title="taplocal | Restaurants">
      <section className="block pb-details">
        <div className="details-topbar text-center">
          <div className="topbar-row details-topbar-top primary-bg-ex">
            <div className="container">
              <h4 className="white m-0">
                We found {state.search.stores.length} stores near{' '}
                <span className="text-capitalize">{state.search.address}!</span>
              </h4>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: 'rgb(53, 38, 8)', color: '#fff', padding: '20px' }}>
          <ul className="d-flex align-items-center justify-content-center">
            <li className="mx-3 lighten-hover" onClick={() => getFilteredStoresByCategory(null)}>
              ALL
            </li>
            {state.appSettings.flags.stores.map((category) => (
              <li className="mx-3 lighten-hover" key={category}>
                <span
                  className="cursor-hover"
                  onClick={() => getFilteredStoresByCategory(category)}
                >
                  {category.toUpperCase()}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="pd-details-main pb-box">
          <div className="container d-flex flex-wrap">
            {state.search.stores.map((store) => (
              <RestaurantListCard key={store.name} store={store} />
            ))}
          </div>
        </div>
      </section>
    </BaseLayout>
  )
}

export default RestaurantListing
