import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import uuidv4 from 'uuid'
import { toast } from 'react-toastify'
import ImageGallery from 'react-image-gallery'
import BaseLayout from '../components/layout/BaseLayout'
import FallBackImage from '../assets/images/main-logo-bg.png'
import { useCart } from '../CartContext'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const toSortedArray = (object) => {
  let arr = []
  Object.keys(object).forEach((objectKey) => {
    let tempOrderWeight = object[objectKey].orderWeight
    delete object[objectKey].orderWeight
    arr.push({
      options: { ...object[objectKey] },
      name: objectKey,
      orderWeight: tempOrderWeight,
    })
  })
  return arr.sort((a, b) => a.orderWeight - b.orderWeight)
}

const ItemDetail = ({ match, history }) => {
  const { dispatch, state } = useCart()
  const [loading, setLoading] = useState(true)
  const [item, setItem] = useState(null)
  const [selectedVariety, setSelectedVariety] = useState(null)
  const [selectedSize, setSelectedSize] = useState('')
  const [canAddToCart, setCanAddToCart] = useState(true)
  const [selectedQuantity, setSelectedQuantity] = useState(1)

  useEffect(() => {
    axios
      .get(`https://api.tapapps.us/taplocal/items/${match.params.itemId}`)
      .then(({ data }) => {
        const cleanedVarieties = toSortedArray(data.item.priceMatrix)
        cleanedVarieties.forEach((variety) => {
          variety.options = toSortedArray(variety.options)
          variety.images = data.item.images[variety.name]
        })
        setItem({
          ...data.item,
          cleanedVarieties,
        })

        setSelectedVariety(cleanedVarieties[0])
        const newSize = cleanedVarieties[0].options.find(
          (option) =>
            option.options.inventory > 0 &&
            option.options.maxQuantity > 0 &&
            option.options.price > 0
        )
        if (newSize) {
          setSelectedSize(newSize.name)
        } else {
          let rollingIndex = 0

          function findSize() {
            if (typeof cleanedVarieties[rollingIndex] === 'undefined') {
              setSelectedVariety(cleanedVarieties[0])
              setSelectedSize(cleanedVarieties[0].options[0].name)
              return
            }

            const size = cleanedVarieties[rollingIndex].options.find(
              (option) =>
                option.options.inventory > 0 &&
                option.options.maxQuantity > 0 &&
                option.options.price > 0
            )
            if (!size && rollingIndex < cleanedVarieties.length) {
              rollingIndex++
              findSize()
            } else {
              setSelectedVariety(cleanedVarieties[rollingIndex])
              setSelectedSize(size.name)
            }
          }

          findSize()
        }

        setLoading(false)
      })
      .catch((err) => {
        toast.error('Sorry we could not load this item.', {
          position: toast.POSITION.TOP_CENTER,
        })
        console.error(err)
      })
  }, [match.params.itemId])

  const addItemToCart = () => {
    const itemToAdd = {
      id: uuidv4(),
      databaseId: item.id,
      name: item.name,
      sku: item.TlItemSku,
      varietyName: item.varietyName,
      sizeName: item.sizeName,
      flags: item.flags,
      selectedVariety: selectedVariety,
      selectedSize: selectedSize,
      maxQuantity: item.maxQuantity,
      quantity: selectedQuantity,
      unitPrice: selectedVariety.options.find((option) => option.name === selectedSize).options
        .price,
      taxFree: item.itemFlags ? item.itemFlags.isTaxFree : false,
      sourcePrice: selectedVariety.options.find((option) => option.name === selectedSize).options
        .sourcePrice,
    }
    dispatch({ type: 'ADD_ITEM', payload: itemToAdd })
    toast.success(`Added ${itemToAdd.name} (${selectedQuantity}) to cart!`, {
      position: toast.POSITION.TOP_CENTER,
    })
    history.push(`/${state.selectedRestaurant.nickname}`)
  }

  const renderPrice = () => {
    const price = selectedVariety.options.find((option) => option.name === selectedSize)
    if (price) {
      return formatter.format(price.options.price / 100)
    } else {
      setCanAddToCart(false)
      return 'Unavailable'
    }
  }

  const renderQuantitySelector = () => {
    const opts = selectedVariety.options.find((option) => option.name === selectedSize)
    const numOptions = Math.min(Math.min(opts.options.inventory, opts.options.maxQuantity), 24)
    return numOptions > 0 ? (
      <select
        className="w-25"
        value={selectedQuantity}
        onChange={(e) => setSelectedQuantity(e.target.value)}
      >
        {Array.from('1'.repeat(numOptions)).map((_, index) => (
          <option value={index + 1} key={index}>
            {index + 1}
          </option>
        ))}
      </select>
    ) : null
  }

  const VarietyImageListItem = ({ variety }) => {
    return (
      <>
        <li className="d-flex flex-column text-center pr-2">
          <img
            key={variety.name}
            alt={variety.name}
            onClick={() => {
              const newSizeExists = variety.options.find(
                (option) =>
                  option.name === selectedSize &&
                  option.options.inventory > 0 &&
                  option.options.maxQuantity > 0 &&
                  option.options.price > 0
              )
              if (!newSizeExists) {
                const newSize = variety.options.find(
                  (option) =>
                    option.options.inventory > 0 &&
                    option.options.maxQuantity > 0 &&
                    option.options.price > 0
                )

                if (!newSize) {
                  toast.error(`This ${item.varietyName.toLowerCase()} option is not available!`, {
                    position: toast.POSITION.TOP_CENTER,
                  })
                  return
                }

                setSelectedSize(newSize.name)
              }
              setSelectedVariety(variety)
            }}
            className={`mx-auto rounded-lg ${
              selectedVariety.name === variety.name
                ? 'selected-border-blue'
                : 'selected-transparent-border faded-image'
            }`}
            style={{ width: '105px' }}
            src={`https://firebasestorage.googleapis.com/v0/b/tap-restaurant-1.appspot.com/o/itemImages%2F${variety.images[0]}?alt=media&token=7d1b6e24-f80b-4841-b9a3-6582d146adff`}
          />
          {variety.name !== 'default' && <span>{variety.name}</span>}
        </li>
      </>
    )
  }

  return (
    <BaseLayout page={item && item.name} title={`taplocal | ${item && item.name}`}>
      {!loading && item.cleanedVarieties ? (
        <>
          <section className="block hero-section my-5">
            <div className="row mx-auto" style={{ maxWidth: '50%' }}>
              <span className="cursor-hover text-primary" onClick={() => history.goBack()}>
                Back
              </span>
            </div>
            <div className="row py-5 d-flex justify-content-center">
              <h1 className="h1">{item.name}</h1>
            </div>
            <div
              className="row d-flex justify-content-center text-center mx-auto"
              style={{ maxWidth: '50%' }}
            >
              <div className="col-6">
                {item && (
                  <ImageGallery
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets
                    showThumbnails={selectedVariety.images.length > 1}
                    onErrorImageURL={FallBackImage}
                    items={selectedVariety.images.map((image) => ({
                      original: `https://firebasestorage.googleapis.com/v0/b/tap-restaurant-1.appspot.com/o/itemImages%2F${image}?alt=media&token=7d1b6e24-f80b-4841-b9a3-6582d146adff`,
                      thumbnail: `https://firebasestorage.googleapis.com/v0/b/tap-restaurant-1.appspot.com/o/itemImages%2F${image}?alt=media&token=7d1b6e24-f80b-4841-b9a3-6582d146adff`,
                    }))}
                  />
                )}
              </div>
              <div className="col-6 text-left">
                {item.description && (
                  <p className="h6 font-weight-bold">
                    {item.description.split('\n').map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          {item}
                          <br />
                        </React.Fragment>
                      )
                    })}
                  </p>
                )}
                {item.sizeName && (
                  <>
                    <span>{item.sizeName}: </span>
                    {selectedVariety.options[0].name !== 'default' && (
                      <select
                        className="my-3 rounded p-2 w-75"
                        value={selectedSize}
                        onChange={(e) => setSelectedSize(e.target.value)}
                      >
                        {selectedVariety.options.map((option, index) => {
                          if (option.options.inventory > 0 && option.options.maxQuantity > 0) {
                            return (
                              <option key={index} value={option.name}>
                                {option.name}
                              </option>
                            )
                          }
                          return null
                        })}
                      </select>
                    )}
                  </>
                )}
                <h2 className="h3 font-weight-bold">{renderPrice()}</h2>
                <span className="h5">
                  {selectedVariety.name !== 'default' &&
                    `${item.varietyName}: ${selectedVariety.name}`}
                </span>
                <ul className="d-flex flex-wrap">
                  {item.cleanedVarieties.map((variety) => (
                    <VarietyImageListItem variety={variety} key={variety.name} />
                  ))}
                </ul>
                <br />
                <span>Quantity: </span>
                {renderQuantitySelector()}
                <br />
                <button
                  onClick={() => {
                    if (canAddToCart) {
                      addItemToCart()
                    }
                  }}
                  className="btn btn-primary mt-3"
                >
                  Add To Cart
                </button>
              </div>
            </div>
            <div className="d-flex flex-column mx-auto mt-4 row" style={{ maxWidth: '50%' }}>
              {item.techSpecs && (
                <>
                  <h3 className="h3">{item.techSpecs.title}:</h3>
                  <p className="h5 font-weight-bold">
                    {item.techSpecs.specs
                      .join('\n')
                      .split('\n')
                      .map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            {item}
                            <br />
                          </React.Fragment>
                        )
                      })}
                  </p>
                </>
              )}
            </div>
          </section>
        </>
      ) : (
        <p>LOADING ITEM...</p>
      )}
    </BaseLayout>
  )
}

export default withRouter(ItemDetail)
