import React from 'react'

const Picker = ({ item, pickerValue, setPickerValue }) => {
  return (
    <div className="col-12">
      <h3>Options:</h3>
      <select value={pickerValue} onChange={e => setPickerValue(e.target.value)} className="form-control mb-3">
        {item.picker.name.map((pickerOption, index) => (
          <option key={index} value={`${pickerOption}=${item.picker.price && item.picker.price[index]}`}>
            {pickerOption} {item.picker.price && item.picker.price[index] !== 0 && '- $' + item.picker.price[index] / 100}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Picker
