import React from 'react'
import BaseLayout from '../components/layout/BaseLayout'
import ThankYouBanner from '../assets/images/thank-you-banner.png'

const ThankYou = ({ location }) => {
  const { orderDetails } = location.state

  return (
    <BaseLayout page="Thank You" title="taplocal | Thank You">
      <section className="block secondary-bg mt-5">
        <div className="container">
          <div className="row">
            <div className="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8 offset-md-2 col-md-8 text-center">
              <div className="phone-intro">
                <h6>Thanks for your order.</h6>
                <h2>
                  We successfully received your {orderDetails.deliveryMethod.toLowerCase()} order!
                </h2>
                <a
                  className="mt-5"
                  href="https://apps.apple.com/us/app/taplocal/id1488953753"
                  target="__blank"
                >
                  <p
                    style={{
                      color: '#3593e3',
                      fontSize: '1.6rem',
                      fontWeight: 'bold',
                      lineHeight: '2rem',
                    }}
                  >
                    If you loved our website, please click here to download our iPhone app! Or, just
                    point your phone at the QR code below.{' '}
                  </p>
                </a>
                <p
                  style={{
                    marginTop: '2rem',
                    color: '#3593e3',
                    fontSize: '1.6rem',
                    fontWeight: 'bold',
                  }}
                >
                  First delivery is free!
                </p>
                <a
                  className="mt-5"
                  href="https://apps.apple.com/us/app/taplocal/id1488953753"
                  target="__blank"
                >
                  <img
                    src={ThankYouBanner}
                    alt="banner"
                    style={{ border: '1px solid #ccc', borderRadius: '5px' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </BaseLayout>
  )
}

export default ThankYou
