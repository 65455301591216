import React, { useState } from 'react'
import BaseLayout from '../../components/layout/BaseLayout'
import RestaurantSearchSection from './RestaurantSearchSection'
import CarouselSection from './CarouselSection'
import EmailFormSection from './EmailFormSection'
import { useCart } from '../../CartContext'
import { toast } from 'react-toastify'
import { geocodeLocation } from '../../helpers/app-helpers'
import { getNearbyStores } from '../../helpers/restaurant-helpers'

const Home = ({ history }) => {
  const { state, dispatch } = useCart()
  const [searching, setSearching] = useState(false)
  const [searchLocation, setSearchLocation] = useState('')

  const submitSearch = async (location) => {
    try {
      setSearching(true)

      const { lat, long, address } = await geocodeLocation(location)

      const stores = await getNearbyStores(lat, long, state.appSettings)

      if (!stores || stores.length === 0) {
        toast.error(`Sorry, there are no stores near ${address}`, {
          position: toast.POSITION.TOP_CENTER,
        })
        setSearching(false)

        return
      }

      dispatch({
        type: 'SET_SEARCHED_STORES',
        payload: { address, stores, lat, long },
      })

      history.push({
        pathname: '/stores',
      })
    } catch (err) {
      console.error(err)
      toast.error('An error occured while searching!', {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  return (
    <BaseLayout page="Home" title="taplocal | Home">
      <RestaurantSearchSection
        submitSearch={submitSearch}
        searching={searching}
        searchLocation={searchLocation}
        setSearchLocation={setSearchLocation}
      />
      <section className="tfood-carousel secondary-bg block">
        <CarouselSection />
        <EmailFormSection />
      </section>
    </BaseLayout>
  )
}

export default Home
