import React from 'react'

const MenuSections = ({ restaurant, selectedMenuSection, setSelectedMenuSection }) => {
  return (
    <div
      className="topbar-row details-topbar-bottom bg-light-gray"
      style={{ color: '#fff', fontSize: '1.3rem', backgroundColor: 'rgb(53, 38, 8)' }}
    >
      <div className="container">
        <ul className="menu-section-bar">
          {restaurant.inventory.map((invItem, index) => {
            return (
              <li
                className={selectedMenuSection === invItem.name ? 'selected-section' : ''}
                onClick={() => {
                  setSelectedMenuSection(invItem.name)
                  localStorage.setItem('selected-section', invItem.name)
                }}
                key={index}
              >
                {invItem.name}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default MenuSections
