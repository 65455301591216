import React, { useState } from 'react'
import PlayStoreBtn from '../../assets/images/play-store-btn.png'
import { toast } from 'react-toastify'
import { sendMarketingEmail } from '../../helpers/app-helpers'

const EmailFormSection = () => {
  const [email, setEmail] = useState('')

  const sendEmail = async () => {
    try {
      await sendMarketingEmail(email)
      toast.success('Please check your email!', { position: toast.POSITION.TOP_CENTER })
      setEmail('')
    } catch (err) {
      console.error(err)
      toast.error('An error occured while searching!', { position: toast.POSITION.TOP_CENTER })
    }
  }

  return (
    <div className="row">
      <div className="email-form offset-xl-3 col-xl-6 offset-lg-2 col-lg-8 offset-md-2 col-md-8 text-center">
        <div className="phone-intro">
          <h6>taplocal</h6>
          <h2>Get it on your smartphone!</h2>
        </div>
        <div className="form mt-4">
          <div className="input-wrap position-relative invite-input">
            <form
              onSubmit={(e) => {
                e.preventDefault()
                sendEmail()
              }}
            >
              <input
                type="email"
                className="input input-radial has-ab-button bg-transparent dark-gray input-bordered"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="btn btn-primary btn-lg btn-radial btn-icon btn-abs">
                Send Invite
              </button>
            </form>
          </div>
        </div>
        <div className="download-button-gr">
          <h6 className="dark-gray opacity-3">or Download From</h6>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/taplocal/id1488953753"
          >
            <img src={PlayStoreBtn} alt="play store" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default EmailFormSection
