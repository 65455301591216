import React from 'react'

const RestaurantOpenStatus = ({ restaurant }) => {
  return (
    <>
      {restaurant.onVacation && (
        <p className="text-center mt-4" style={{ color: 'red' }}>
          Sorry, this restaurant is currently on vacation!
        </p>
      )}
      {restaurant.isOpen === 0 && (
        <p className="text-center mt-4" style={{ color: 'red' }}>
          Restaurant is closed at the moment!
        </p>
      )}
      {restaurant.isOpen === 1 && (
        <p className="text-center mt-4" style={{ color: 'red' }}>
          This restaurant is closing soon!
        </p>
      )}
    </>
  )
}

export default RestaurantOpenStatus
