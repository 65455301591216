import React from 'react'

const TableOptions = ({ item, tableValues, setTableValues }) => {
  const addOption = (event, selectedOption) => {
    const [optionName, headerName, enabled] = selectedOption.split(':')

    let newGroup = []

    if (enabled === 'true') {
      const theItem = item.table.find((ti) => ti.header === headerName)

      if (tableValues[headerName]) {
        // Check if max selected
        if (
          tableValues[headerName].length < theItem.maxAllow ||
          tableValues[headerName].length < 1
        ) {
          newGroup = [...tableValues[headerName], optionName]
        } else {
          newGroup = [...tableValues[headerName]]
          event.target.checked = false
          alert(`You have selected the max amount of options for this option.`)
        }
      } else {
        newGroup = [optionName]
      }
    } else {
      newGroup = tableValues[headerName].filter((header) => header !== optionName)
    }

    setTableValues({ ...tableValues, [headerName]: newGroup })
  }

  return (
    <div className="col-12">
      {item.table &&
        item.table.length > 0 &&
        item.table.map((tableItem, index) => (
          <div key={index}>
            <h3>{tableItem.header}:</h3>
            <ul className="check-list">
              {tableItem.name.map((checkbox, i) => (
                <li key={i}>
                  <input
                    type="checkbox"
                    id={checkbox + i}
                    name={`${checkbox}=${tableItem.price ? tableItem.price[i] : 0}`}
                    onChange={(e) =>
                      addOption(e, e.target.name + ':' + tableItem.header + ':' + e.target.checked)
                    }
                    // checked={tableItem.defaultOption !== undefined && tableItem.defaultOption == i}
                  />
                  <label htmlFor={checkbox + i}>
                    {checkbox} {tableItem.price && '- $' + (tableItem.price[i] / 100).toFixed(2)}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        ))}
    </div>
  )
}

export default TableOptions
