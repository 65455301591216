import React from 'react'

import TaplocalLogo from '../assets/images/taplocal.png'
import StripeBtn from '../assets/images/stripe-connect.png'

const RegisterTaplocal = ({ match }) => {
  return (
    <div className="register">
      <div className="logo">
        <img src={TaplocalLogo} alt="Logo" />
      </div>
      <div className="content">
        <h1>Thank you for joining taplocal, {match.params.client}!</h1>
        <p>
          We are very excited that you are joining the taplocal family. It is the perfect way to
          connect with your customers and make money in the process.
        </p>
        <p>
          In order to start accepting payments via Stripe, please click the button below and follow
          the registration process. It usually takes 10 minutes or less. Once connected with Stripe,
          you will get the confirmation that the link has been made.
        </p>
        <a
          href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_GVVxfCo87WbMs2DgFxYk1Zk6jjn0PGR6&scope=read_write&state=${match.params.client}`}
        >
          <img className="" src={StripeBtn} alt="Stripe Button" />
        </a>
      </div>
    </div>
  )
}

export default RegisterTaplocal
