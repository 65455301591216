import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import Screen1 from '../../assets/images/slideshow/screen1.png'
import Screen2 from '../../assets/images/slideshow/screen2.png'
import Screen3 from '../../assets/images/slideshow/screen3.png'
import Screen4 from '../../assets/images/slideshow/screen4.png'
import Screen5 from '../../assets/images/slideshow/screen5.png'
import ReviewIcon from '../../assets/images/review-icon.png'
import 'react-alice-carousel/lib/alice-carousel.css'

const CarouselSection = () => {
  return (
    <div className="container clearfix">
      <div className="row">
        <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6">
          <div className="section-title text-center">
            <h2>taplocal interface</h2>
            <h6>Landing Screen</h6>
          </div>
        </div>
      </div>
      <AliceCarousel
        buttonsDisabled={true}
        mouseDragEnabled
        autoPlay={true}
        autoPlayInterval={3200}
        responsive={{ 0: { items: 1 }, 1024: { items: 4 } }}
      >
        <img
          alt="slideshow-0"
          src={Screen1}
          onDragStart={(e) => e.preventDefault()}
          className="yours-custom-class"
        />
        <img
          alt="slideshow-1"
          src={Screen2}
          onDragStart={(e) => e.preventDefault()}
          className="yours-custom-class"
        />
        <img
          alt="slideshow-2"
          src={Screen3}
          onDragStart={(e) => e.preventDefault()}
          className="yours-custom-class"
        />
        <img
          alt="slideshow-3"
          src={Screen4}
          onDragStart={(e) => e.preventDefault()}
          className="yours-custom-class"
        />
        <img
          alt="slideshow-4"
          src={Screen5}
          onDragStart={(e) => e.preventDefault()}
          className="yours-custom-class"
        />
      </AliceCarousel>
      <br />
      <br />
      <br />
      {/* <div className="carousel-wrap"> */}
      {/* <div className="iphone-mock-top"></div>
      <div className="iphone-mock-center position-relative">
        <div className="screen" id="screen-carousel">
          <div className="screen-item">
            <div className="screen-bg transition-4" style={{ backgroundImage: `url(${Screen1})` }}></div>
          </div>
          <div className="screen-item">
            <div className="screen-bg transition-4" style={{ backgroundImage: `url(${Screen1})` }}></div>
          </div>
          <div className="screen-item">
            <div className="screen-bg transition-4" style={{ backgroundImage: `url(${Screen1})` }}></div>
          </div>
          <div className="screen-item">
            <div className="screen-bg transition-4" style={{ backgroundImage: `url(${Screen1})` }}></div>
          </div>
          <div className="screen-item">
            <div className="screen-bg transition-4" style={{ backgroundImage: `url(${Screen1})` }}></div>
          </div>
        </div>
      </div>
      <div className="iphone-mock-bottom"></div> */}
      {/* </div> */}
      <br />
      <hr />
      <br />
      <AliceCarousel
        buttonsDisabled={true}
        mouseDragEnabled
        autoPlay={true}
        autoPlayInterval={6800}
      >
        <div className="avatar-content-item text-center">
          <h3 className="yeseva">Wonderful!</h3>
          <blockquote>
            <br /> They truly want 100% costumer satisfaction.
          </blockquote>
          <div className="review-icon">
            <img alt="slideshow0" src={ReviewIcon} className="block-center" />
          </div>
          <h6 className="defult-color mb-0">Stephanie</h6>
        </div>

        <div className="avatar-content-item text-center">
          <h3 className="yeseva">Easy to Use</h3>
          <blockquote>
            <em>taplocal</em> is, by far, the easiest food app I've used
          </blockquote>
          <div className="review-icon">
            <img alt="slideshow1" src={ReviewIcon} className="block-center" />
          </div>
          <h6 className="defult-color mb-0">Bill</h6>
        </div>
        <div className="avatar-content-item text-center">
          <h3 className="yeseva">No Login</h3>
          <blockquote>
            Coool! So you don't need a login. That's awesome! I .... hate setting up accounts and
            getting junk mail.
          </blockquote>
          <div className="review-icon">
            <img alt="slideshow2" src={ReviewIcon} className="block-center" />
          </div>
          <h6 className="defult-color mb-0">Kim</h6>
        </div>
        <div className="avatar-content-item text-center">
          <h3 className="yeseva">Love it! </h3>
          <blockquote>
            Love it! We definitely prefer it over seamless ... it was really easy to use!
          </blockquote>
          <div className="review-icon">
            <img alt="slideshow3" src={ReviewIcon} className="block-center" />
          </div>
          <h6 className="defult-color  mb-0">Pam</h6>
        </div>
        <div className="avatar-content-item text-center">
          <h3 className="yeseva">Easiest Food App</h3>
          <blockquote>
            <em>taplocal</em> is, by far, the easiest food app I've used and I have used them all.
            <br />
            <em>taplocal</em> just hooks to ApplePay. So simple, so easy!
          </blockquote>
          <div className="review-icon">
            <img alt="slideshow4" src={ReviewIcon} className="block-center" />
          </div>
          <h6 className="defult-color mb-0">Sandi</h6>
        </div>
      </AliceCarousel>
      <br />
      <br />
      <br />
    </div>
  )
}

export default CarouselSection
