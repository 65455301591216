import React from 'react'

import TapfoodLogo from '../assets/images/main-logo.png'
import StripeBtn from '../assets/images/stripe-connect.png'

const RegisterTapfood = ({ match }) => {
  return (
    <div className="register">
      <div className="logo">
        <img className="" src={TapfoodLogo} alt="Logo" />
      </div>
      <div className="content">
        <h1>Thank you for joining taplocal, {match.params.client}!</h1>
        <p>
          We are very excited that you are joining the taplocal family. It is the perfect way to
          connect with your customers and make money in the process.
        </p>
        <p>
          In order to start accepting payments via Stripe, please click the button below and follow
          the registration process. It usually takes 10 minutes or less. Once connected with Stripe,
          you will get the confirmation that the link has been made.
        </p>
        <a
          href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_DpujLp2KoaF7kZrz7BoTr71KtyIpKpDr&scope=read_write&state=${match.params.client}`}
        >
          <img className="" src={StripeBtn} alt="Stripe Button" />
        </a>
      </div>
    </div>
  )
}

export default RegisterTapfood
