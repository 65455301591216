import React from 'react'
import { ClipLoader } from 'react-spinners'

const Spinner = () => {
  return (
    <div className="text-center mt-5">
      <ClipLoader sizeUnit={'px'} size={80} color={'#1e8bc3'} />
      <p>Loading...</p>
    </div>
  )
}

export default Spinner
