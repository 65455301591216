import axios from 'axios'
import { geocodeLocation } from './app-helpers'

export function formatCartItems(items) {
  return items
}

export function calculateSubTotal(items) {
  let total = 0

  if (items.length === 0) {
    return 0
  }

  items.forEach((item) => {
    total += item.unitPrice * item.quantity
  })

  return total
}

export function calculateTaxableSubTotal(items) {
  let taxableTotal = 0

  if (items.length === 0) {
    return 0
  }

  items.forEach((item) => {
    if (!item.taxFree) {
      taxableTotal += item.unitPrice * item.quantity
    }
  })

  return taxableTotal
}

export function isDoingDeliveries(schedule) {
  if (!schedule) {
    return true
  }

  const now = new Date()

  let dayOfTheWeek = now.getDay()

  let timeDecimal = now.getHours() + now.getMinutes() / 60

  if (dayOfTheWeek === 0) {
    dayOfTheWeek = 7
  }

  const time = schedule[dayOfTheWeek.toString()]

  if (!time || time.toString() === '-999') {
    return false
  }

  let [openToClose1, openToClose2] = time.toString().split(';')

  if (!openToClose2) {
    openToClose2 = openToClose1
  }

  let [openTime, closeTime] = openToClose1.split('-')
  openTime = Number(openTime)
  closeTime = Number(closeTime)

  let [openTime2, closeTime2] = openToClose2.split('-')
  openTime2 = Number(openTime2)
  closeTime2 = Number(closeTime2)

  const stopOrders = 0

  if (closeTime - openTime >= 24) {
    return true
  }

  let result = false

  if (
    (openTime <= timeDecimal && timeDecimal <= closeTime - stopOrders / 60) ||
    (openTime2 <= timeDecimal && timeDecimal <= closeTime2 - stopOrders / 60)
  ) {
    result = true
  }

  return result
}

function makeHour(timeDecimal) {
  let ret1 = ''
  let suffix = 'AM'
  let hour = Number(Math.floor(timeDecimal))

  if (hour > 24) {
    hour = hour - 24
  }

  let minute = Number(60.0 * (timeDecimal - Math.floor(timeDecimal)))

  if (minute < 10) {
    minute = `0${minute}`
  }

  if (hour > 11) {
    suffix = 'PM'

    if (hour > 12) {
      hour = hour - 12
    }
  }

  ret1 = `${hour}:${minute} ${suffix}`

  if (timeDecimal === 12) {
    ret1 = '12 PM'
  }
  if (timeDecimal === 24) {
    ret1 = '12 AM'
  }
  return ret1
}

export function generatePrettySchedule(schedule) {
  if (!schedule) {
    return true
  }

  const now = new Date()

  let dayOfTheWeek = now.getDay()

  if (dayOfTheWeek === 0) {
    dayOfTheWeek = 7
  }

  const time = schedule[dayOfTheWeek.toString()]

  if (time.toString() === '-999') {
    return false
  }

  let [openToClose1, openToClose2] = time.toString().split(';')

  if (!openToClose2) {
    openToClose2 = openToClose1
  }

  let [openTime, closeTime] = openToClose1.split('-')
  openTime = Number(openTime)
  closeTime = Number(closeTime)

  let result = `Deliveries today: ${makeHour(openTime)} - ${makeHour(closeTime)}`

  let [openTime2, closeTime2] = openToClose2.split('-')
  openTime2 = Number(openTime2)
  closeTime2 = Number(closeTime2)

  if (openTime2 !== openTime) {
    result += `; ${makeHour(openTime2)} - ${makeHour(closeTime2)}`
  }

  return result
}

export async function calculateDistance(restaurant, userAddress) {
  try {
    const { latitude, longitude } = restaurant

    const { lat, long } = await geocodeLocation(userAddress)

    const earthRadius = 6371000 // In meters

    const phi1 = (Math.PI / 180) * lat // user
    const phi2 = (Math.PI / 180) * latitude // resto

    const lon1 = (Math.PI / 180) * long // user
    const lon2 = (Math.PI / 180) * longitude //resto

    const dPhi = phi2 - phi1
    const dLambda = lon2 - lon1

    const a =
      Math.sin(dPhi / 2.0) * Math.sin(dPhi / 2.0) +
      Math.cos(phi1) * Math.cos(phi2) * Math.sin(dLambda / 2.0) * Math.sin(dLambda / 2.0)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1.0 - a))

    return earthRadius * c // Meters
  } catch (err) {
    throw err
  }
}

export async function getDistanceFee(restaurant, address) {
  try {
    const { deliveryRadius, deliveryFee } = restaurant

    const distanceInMeters = await calculateDistance(restaurant, address)

    if (!restaurant.deliveryByTap && distanceInMeters > deliveryRadius) {
      return null
    }

    if (distanceInMeters > deliveryRadius && distanceInMeters < deliveryRadius * 3) {
      const perMilePrice = Math.max(
        (1.25 * 1600.0 * deliveryFee) / Math.max(1000.0, deliveryRadius),
        120.0
      )
      const ret1 = Number(
        Math.round((perMilePrice * (distanceInMeters - deliveryRadius)) / 1600.0 / 50) * 50.0
      )
      return ret1
    }

    if (distanceInMeters > deliveryRadius * 3) {
      return null
    }

    if (distanceInMeters < deliveryRadius) {
      return 0
    }

    return null
  } catch (err) {
    throw err
  }
}

export function generateOrderDetailsPlainText(items, orderDetails) {
  let string = ''

  if (orderDetails.forLater) {
    string += `FOR LATER: ${orderDetails.forLaterDate.toLocaleDateString()} at ${orderDetails.forLaterDate.toLocaleTimeString()}\n`
  }

  string += '\n'

  items.forEach((item, i) => {
    string += `${item.quantity}x ${item.name.split('|')[0]} - ${item.quantity} x $${(
      item.unitPrice / 100
    ).toFixed(2)} = $${((item.quantity * item.unitPrice) / 100).toFixed(2)}`

    item.selectedVariety.name !== 'default' &&
      (string += `\n${item.varietyName}: ${item.selectedVariety.name}`)

    item.selectedSize !== 'default' && (string += `\n${item.sizeName}: ${item.selectedSize}`)

    string += '\n'
  })

  string += 'PAID IN FULL and ordered with taplocal web'

  string += `\n\n Instructions: ${orderDetails.notes}`

  string += `\n\n Subtotal: $${(orderDetails.subtotal / 100).toFixed(2)}`

  if (orderDetails.discount > 0) {
    string += `\n Discount: -$${orderDetails.discount}`
  }

  if (orderDetails.deliveryFee > 0) {
    string += `\n Delivery Fee: $${(orderDetails.deliveryFee / 100).toFixed(2)}`
  }

  if (orderDetails.distanceFee > 0) {
    string += `\n Distance Fee: $${(orderDetails.distanceFee / 100).toFixed(2)}`
  }

  if (orderDetails.tip > 0) {
    string += `\n Tip: $${(orderDetails.tip / 100).toFixed(2)}`
  }

  string += `\n Tax: $${(orderDetails.tax / 100).toFixed(2)}`
  string += `\n Total: $${(
    (orderDetails.subtotal +
      orderDetails.deliveryFee +
      orderDetails.distanceFee +
      orderDetails.tip +
      orderDetails.tax -
      orderDetails.discount * 100) /
    100
  ).toFixed(2)}\n\n`
  string += `Hate entering your credit card? Please download our taplocal app and pay with Apple pay at: https://apps.apple.com/us/app/taplocal/id1488953753`

  return string
}

export function generateOrderDetailsHTML(items, orderDetails) {
  let html = '<html><body>'

  if (orderDetails.forLater) {
    html += `<br/><strong style="font-size: 1.6rem;">FOR LATER: ${orderDetails.forLaterDate.toLocaleDateString()} at ${orderDetails.forLaterDate.toLocaleTimeString()}</strong><br/>`
  }

  items.forEach((item, i) => {
    html += `<strong style="font-size: 1.3rem;">${item.quantity}x ${item.name.split('|')[0]} - ${
      item.quantity
    } x $${(item.unitPrice / 100).toFixed(2)} = $${((item.quantity * item.unitPrice) / 100).toFixed(
      2
    )}<strong>`

    item.selectedVariety.name !== 'default' &&
      (html += `<br/><span>${item.varietyName}: ${item.selectedVariety.name}</span>`)

    item.selectedSize !== 'default' &&
      (html += `<br/><span>${item.sizeName}: ${item.selectedSize}</span>`)

    html += '<hr/>'
  })

  html += '<small style="color: #1e8bc3;"> PAID IN FULL and ordered with taplocal web </small><br/>'

  html += `<hr/><br/> <small>Instructions: ${orderDetails.notes}</small>`

  let sum = 0
  items.forEach((item) => (sum += item.quantity))

  html += `<hr/><br/><small>Cart: ${sum} items</small>`

  html += `<hr/><br/> <small>Subtotal: $${(orderDetails.subtotal / 100).toFixed(2)}</small>`

  if (orderDetails.discount > 0) {
    html += `<hr/><br/> <small>Discount: -$${orderDetails.discount}</small>`
  }

  if (orderDetails.deliveryFee > 0) {
    html += `<br/> <small>Delivery Fee: $${(orderDetails.deliveryFee / 100).toFixed(2)}</small>`
  }

  if (orderDetails.distanceFee > 0) {
    html += `<br/> <small>Distance Fee: $${(orderDetails.distanceFee / 100).toFixed(2)}</small>`
  }

  if (orderDetails.tip > 0) {
    html += `<br/> <small>Tip: $${(orderDetails.tip / 100).toFixed(2)}</small>`
  }

  html += `<br/> <small>Tax: $${(orderDetails.tax / 100).toFixed(2)}</small>`
  html += `<br/> <small>Total: $${(
    (orderDetails.subtotal +
      orderDetails.deliveryFee +
      orderDetails.distanceFee +
      orderDetails.tip +
      orderDetails.tax -
      orderDetails.discount * 100) /
    100
  ).toFixed(2)}</small><hr/>`

  html += '</body></html>'
  return html
}

// Calculate the tapfood fee
export function calculateTapfoodFee(restaurant, orderDetails) {
  const BASE_PERCENTAGE = 0.05
  const DISCOUNT = restaurant.applicationFeeDiscount
  const resto = restaurant

  let fee = Math.max(0, orderDetails.subtotal * (BASE_PERCENTAGE - DISCOUNT))

  // If delivery by tap
  if (restaurant.deliveryByTap) {
    // If order is delivery
    if (orderDetails.deliveryMethod === 'Delivery') {
      let surchargeRatio = 0
      if (resto.tapLocalDeliverySurchargeRatio) {
        surchargeRatio = resto.tapLocalDeliverySurchargeRatio
      }
      let tapTipsRatio = 1
      if (resto.flags.tapTipsRatio) {
        tapTipsRatio = resto.flags.tapTipsRatio
      }

      fee += surchargeRatio * orderDetails.subtotal
      fee += resto.tapLocalDeliverySurchargeCents
      fee += orderDetails.tip * tapTipsRatio
      fee += orderDetails.distanceFee
      fee += orderDetails.deliveryFee
    } else {
      // If order is not a delivery
      if (resto.applicationFeeDiscount > 0) {
        fee += orderDetails.tip
      }
    }

    // If the order not a delivery and restaurant does not keep tips
  } else if (orderDetails.deliveryMethod !== 'Delivery') {
    fee += orderDetails.tip
  }

  return Math.round(fee)
}

export function calculateOwedToDriver(appSettings, restaurant, orderDetails) {
  const minOwedToDriver = appSettings.flags.minPayDriver

  const deliveryFeeToDriverRatio = appSettings.flags.deliveryFeeOwedToDriverRatio

  let owedToDriver = 0

  let tapTipsRatio = 1

  if (restaurant.flags.tapTipsRatio) {
    tapTipsRatio = restaurant.flags.tapTipsRatio
  }

  if (restaurant.deliveryByTap && orderDetails.deliveryMethod === 'Delivery') {
    owedToDriver = Math.max(
      minOwedToDriver,
      deliveryFeeToDriverRatio * (orderDetails.deliveryFee + orderDetails.distanceFee) +
        orderDetails.tip * tapTipsRatio
    )
  }

  return Math.round(owedToDriver)
}

export function generateOrderObject(orderDetails, restaurant, order_details, appSettings) {
  try {
    const order = {
      forLater: orderDetails.forLater,
      scheduledDeliveryTime: orderDetails.forLater ? orderDetails.forLaterDate : null,
      address: orderDetails.address,
      customerPhone: orderDetails.phone,
      deliveryMethod: orderDetails.deliveryMethod,
      deliveryTapfood: restaurant.deliveryByTap,
      order: order_details,
      discount: orderDetails.discount * 100,
      paidDriver: 0,
      notes: orderDetails.notes,
      subtotal: Math.round(orderDetails.subtotal),
      tapfoodFee: calculateTapfoodFee(restaurant, orderDetails),
      tax: Math.round(orderDetails.tax),
      time: new Date(),
      tip: Math.round(orderDetails.tip),
      total: Math.round(
        orderDetails.distanceFee +
          orderDetails.deliveryFee +
          orderDetails.tax +
          orderDetails.subtotal +
          orderDetails.tip -
          orderDetails.discount * 100
      ),
      tlStoreNickname: restaurant.nickname,
      version: 'web',
      customerName: orderDetails.fullName,
      discountOwedToRestaurant: 0,
      restaurantLatitude: restaurant.latitude,
      restaurantLongitude: restaurant.longitude,
      customerLatitude: -999,
      customerLongitude: -999,
      initialDiscount: 0,
      deliveryCharge: null,
      owedToDriver: calculateOwedToDriver(appSettings, restaurant, orderDetails),
    }

    return order
  } catch (err) {
    throw err
  }
}

function calculateTaxLeftover(items, resto) {
  let total = 0
  items.forEach((item) => {
    if (!item.taxFree) {
      total += item.quantity * (item.unitPrice - item.sourcePrice)  
    }
  })
  return Math.ceil(total * resto.taxRate)
}

export async function processOrder(items, restaurant, orderDetails, token, appSettings) {
  try {
    const order_details = generateOrderDetailsPlainText(items, orderDetails)
    const order_details_html = generateOrderDetailsHTML(items, orderDetails)

    const orderObject = generateOrderObject(orderDetails, restaurant, order_details, appSettings)

    const forLaterString = `${
      orderDetails.forLater
        ? `FOR LATER AT ${
            orderDetails.forLaterDate.toLocaleDateString() +
            ' ' +
            orderDetails.forLaterDate.toLocaleTimeString()
          } - `
        : ''
    }`

    const requestBody = {
      token: token.id,
      subtotal: orderDetails.subtotal,
      discount: orderDetails.discount,
      deliveryMethod: orderDetails.deliveryMethod,
      amount: Math.round(
        orderDetails.deliveryFee +
          orderDetails.distanceFee +
          orderDetails.subtotal +
          orderDetails.tip +
          orderDetails.tax -
          orderDetails.discount * 100
      ),
      tip: orderDetails.tip,
      application_fee: calculateTapfoodFee(restaurant, orderDetails),
      currency: 'usd',
      notes: orderDetails.notes,
      description: `${restaurant.name} Store`,
      restaurant_id: restaurant.nickname,
      order_title: `${forLaterString} ${orderDetails.deliveryMethod} for ${
        orderDetails.fullName
      } @ ${orderDetails.phone}, ${
        orderDetails.deliveryMethod === 'Delivery' ? `${orderDetails.address}` : ''
      } from ${restaurant.name} on ${new Date().toString().split('GMT')[0] + 'EST'}`,
      order_info: `${orderDetails.deliveryMethod} for ${orderDetails.fullName} @ ${
        orderDetails.phone
      }, ${orderDetails.deliveryMethod === 'Delivery' ? `${orderDetails.address}` : ''} from ${
        restaurant.name
      } on ${new Date().toString().split('GMT')[0] + 'EST'}`,
      order_details,
      order_details_html,
      client_phone_number: restaurant.phone,
      client_phone_number_cell: restaurant.phoneNumberCell,
      client_email: restaurant.email,
      customerPhoneNumber: orderDetails.phone,
      confirmation_method_raw: restaurant.confirmationMethod,
      fax_number: restaurant.faxNumber,
      source: 'web',
      orderInfo: orderObject,
      app: 'taplocal',
      taxLeftover: calculateTaxLeftover(items, restaurant),
    }

    const { data } = await axios.post('https://api.tapapps.us/taplocal/new-payment', requestBody)

    const { customerSavedAmount } = data

    return { customerSavedAmount }
  } catch (err) {
    throw err
  }
}

export async function trackUser(orderDetails, restaurant) {
  try {
    const e = Number(Math.floor(Math.random() * 1000000 + 1))
    const c = Number((Math.sqrt(e + 5) * 1000000).toString().substr(0, 5))

    const body = {
      customerPhone: orderDetails.phone,
      customerName: orderDetails.fullName,
      appVersion: 'web',
      app: 'taplocal',
      restaurantName: restaurant.name,
      subtotal: orderDetails.subtotal,
    }

    await axios.post(`https://api.tapapps.us/orders/update-user`, {
      ...body,
      password: 'Barimba1!',
      c,
      e,
    })
  } catch (err) {
    throw err
  }
}

export function getDollarDiscount(specialIncentives, subtotal) {
  const getDayDigit = () => {
    const now = new Date()
    let dayOfTheWeek = now.getDay()
    if (dayOfTheWeek === 0) {
      dayOfTheWeek = 7
    }
    return dayOfTheWeek
  }

  const dayDigit = getDayDigit()

  if (specialIncentives) {
    if (Object.keys(specialIncentives).some((key) => key.toString() === dayDigit.toString())) {
      const incentives = specialIncentives[dayDigit]

      if (
        incentives.discountFraction &&
        incentives.discountCutoff + 1 >= 1 &&
        incentives.discountCutoff <= subtotal
      ) {
        return ((subtotal * incentives.discountFraction) / 100).toFixed(2)
      }
    }
  }

  return 0
}
