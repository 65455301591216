import React, { useEffect, useState } from 'react'
import Spinner from './components/common/Spinner'
import { toast } from 'react-toastify'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { CartProvider, useCart } from './CartContext'
import { initializeApp } from './helpers/app-helpers'
import {
  Home,
  RestaurantListing,
  RestaurantMenu,
  Cart,
  ThankYou,
  Error404,
  Jobs,
  RegisterTapfood,
  RegisterTapfoodCallback,
  RegisterTapfoodThankYou,
  RegisterTaplocal,
  RegisterTaplocalCallback,
  RegisterTaplocalThankYou,
  ItemDetail,
} from './pages'

import 'react-image-gallery/styles/css/image-gallery.css'

toast.configure()

function App() {
  const { dispatch } = useCart()
  const [appLoading, setAppLoading] = useState(true)

  useEffect(() => {
    initializeApp()
      .then(({ cartItems, selectedStore, appSettings }) => {
        dispatch({ type: 'SET_ITEMS', payload: cartItems })
        dispatch({
          type: 'SET_SELECTED_RESTAURANT',
          payload: selectedStore,
        })
        dispatch({ type: 'SET_APP_SETTINGS', payload: appSettings })
        setAppLoading(false)
      })
      .catch((err) => {
        console.error(err)
        alert('We could not load taplocal at this time. Try again later!')
        setAppLoading(false)
      })
  }, [dispatch])

  if (appLoading) {
    return <Spinner />
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/stores" component={RestaurantListing} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/thank-you" component={ThankYou} />
        <Route exact path="/drive-for-us" component={Jobs} />

        <Route exact path="/register/callback" component={RegisterTapfoodCallback} />
        <Route exact path="/register/thank-you" component={RegisterTapfoodThankYou} />
        <Route exact path="/register/:client" component={RegisterTapfood} />

        <Route exact path="/register/taplocal/callback" component={RegisterTaplocalCallback} />
        <Route exact path="/register/taplocal/thank-you" component={RegisterTaplocalThankYou} />
        <Route exact path="/register/taplocal/:client" component={RegisterTaplocal} />

        <Route exact path="/:restaurantId" component={RestaurantMenu} />
        <Route exact path="/:item/:itemId" component={ItemDetail} />
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  )
}

function AppWrapper() {
  return (
    <CartProvider>
      <App />
    </CartProvider>
  )
}

export default AppWrapper
