import axios from 'axios'

export function restaurantOnVacation(restaurant, vacationUS) {
  if (vacationUS) {
    for (let i = 0; i < vacationUS.length; i++) {
      let dateString = vacationUS[i]

      let beginningDate
      let endDate

      if (dateString.includes('-')) {
        beginningDate = dateString.split('-')[0]
        endDate = dateString.split('-')[1]
      } else {
        beginningDate = dateString
        endDate = dateString
      }

      beginningDate = Number(beginningDate)
      endDate = Number(endDate)

      const today = Number(new Date().toISOString().slice(0, 10).replace(/-/g, ''))

      if (today <= endDate && today >= beginningDate) {
        return true
      }
    }
  }

  let { vacation } = restaurant

  if (!vacation) {
    return false
  }

  for (let i = 0; i < vacation.length; i++) {
    let dateString = vacation[i]

    let beginningDate
    let endDate

    if (dateString.includes('-')) {
      beginningDate = dateString.split('-')[0]
      endDate = dateString.split('-')[1]
    } else {
      beginningDate = dateString
      endDate = dateString
    }

    beginningDate = Number(beginningDate)
    endDate = Number(endDate)

    const today = Number(new Date().toISOString().slice(0, 10).replace(/-/g, ''))

    if (today <= endDate && today >= beginningDate) {
      return true
    }
  }
}

export function storeIsOpen(store, vacationUS) {
  if (restaurantOnVacation(store, vacationUS)) {
    return 0
  }

  const now = new Date()

  let dayOfTheWeek = now.getDay()

  let timeDecimal = now.getHours() + now.getMinutes() / 60

  if (dayOfTheWeek === 0) {
    dayOfTheWeek = 7
  }

  const time = store.schedule[dayOfTheWeek.toString()]

  if (!time) {
    return 0
  }

  if (time.toString() === '-999') {
    return 0
  }

  let yesterdaysClose = dayOfTheWeek - 1 === 0 ? 7 : dayOfTheWeek - 1

  yesterdaysClose = yesterdaysClose.toString()

  const yesterdaySplit = yesterdaysClose.split('-')
  const key = yesterdaySplit[yesterdaySplit.length - 1]
  const value = store.schedule[key]
  const splitArray = value.toString().split('-')
  let yesterdayLastClose = Number(splitArray[splitArray.length - 1]) - 24

  let [openToClose1, openToClose2] = time.toString().split(';')

  if (!openToClose2) {
    openToClose2 = openToClose1
  }

  let [openTime, closeTime] = openToClose1.split('-')
  openTime = Number(openTime)
  closeTime = Number(closeTime)

  let [openTime2, closeTime2] = openToClose2.split('-')
  openTime2 = Number(openTime2)
  closeTime2 = Number(closeTime2)

  let stopOrders = store.stopOrders

  if (!stopOrders) {
    stopOrders = 15
  }

  if (closeTime - openTime >= 24) {
    return 2
  }

  if (Number(value) === -999) {
    yesterdayLastClose = 0
  }

  let result = 0
  if (
    (openTime <= timeDecimal && timeDecimal <= closeTime - stopOrders / 60) ||
    (openTime2 <= timeDecimal && timeDecimal <= closeTime2 - stopOrders / 60)
  ) {
    result = 2

    if (
      (timeDecimal >= closeTime - 1 && timeDecimal < openTime2) ||
      (openTime2 <= timeDecimal && timeDecimal >= closeTime2 - 1)
    ) {
      result = 1
    }
  } else {
    if (timeDecimal < yesterdayLastClose - stopOrders / 60) {
      result = 2

      if (yesterdayLastClose - 1 <= timeDecimal) {
        result = 1
      }
    }
  }

  // 0 = CLOSED
  // 1 = CLOSING SOON
  // 2 = OPEN

  return result
}

const doubleToHour = (double) => {
  const split = double.toString().split('.')
  if (split.length === 2) {
    const doubleThing = parseFloat(`0.${split[1]}`) * 60
    return `${split[0]}:${doubleThing}`
  } else if (split.length === 1) {
    return split[0]
  }
}

const makeNiceHour = (double) => {
  if (double < 12) {
    return doubleToHour(double) + ' AM'
  } else if (double < 24 && double >= 12) {
    return doubleToHour(double - 12) + ' PM'
  } else if (double >= 24) {
    return doubleToHour(double - 24) + ' AM'
  }
}

export const getTodaysSchedule = (store, key) => {
  const now = new Date()

  let dayOfTheWeek = now.getDay()

  if (dayOfTheWeek === 0) {
    dayOfTheWeek = 7
  }

  const time = store[key][dayOfTheWeek.toString()]

  if (!time) {
    return 'CLOSED'
  }

  if (time.toString() === '-999' || time.length === 0) {
    return 'Currently closed.'
  }

  if (time.toString() === '0-25') {
    return 'Open 24 hours!'
  }

  const timeArray = time.split(';')

  let str = ''

  timeArray.forEach((timeGroup, i) => {
    const [start, end] = timeGroup.split('-')
    str += makeNiceHour(start) + ' - ' + makeNiceHour(end)
    if (i === 0 && timeArray.length === 2) {
      str += '; '
    }
  })

  return str
}

export async function getNearbyStores(lat, long, _, category) {
  try {
    let URL = `https://api.tapapps.us/taplocal/nearby?radius=16000&lat=${lat}&long=${long}&maxReturns=100`

    if (category) {
      URL += `&cat=${category}`
    }

    const { data } = await axios.get(URL)

    data.nearbyStores.forEach((store) => {
      store.isOpen = storeIsOpen(store)
    })
    return data.nearbyStores
  } catch (err) {
    throw err
  }
}

export async function getStore(storeNickname) {
  try {
    const appSettings = await axios.get('https://api.tapapps.us/app-settings-local')
    const { data } = await axios.get(`https://api.tapapps.us/taplocal/${storeNickname}`)
    data.onVacation = restaurantOnVacation(data, appSettings.vacationUS)
    data.isOpen = storeIsOpen(data, appSettings.vacationUS)
    return data
  } catch (err) {
    console.error(err)
    if (err.response.status === 404) {
      window.location.href = '/'
    } else {
      throw err
    }
  }
}

export function isLunch(restaurantSettings) {
  const now = new Date()

  let dayOfTheWeek = now.getDay()

  let timeDecimal = now.getHours() + now.getMinutes() / 60

  if (dayOfTheWeek === 0) {
    dayOfTheWeek = 7
  }

  if (restaurantSettings.lunchSchedule && dayOfTheWeek < restaurantSettings.lunchSchedule.length) {
    const [startDay, endDay] = restaurantSettings.lunchSchedule[dayOfTheWeek].split('-')

    if (timeDecimal >= Number(startDay) && timeDecimal <= Number(endDay)) {
      return true
    }
  }

  return false
}

export function isBreakfast(restaurantSettings) {
  const START_DAY = 4.5

  const now = new Date()

  let dayOfTheWeek = now.getDay()

  let timeDecimal = now.getHours() + now.getMinutes() / 60

  if (dayOfTheWeek === 0) {
    dayOfTheWeek = 7
  }

  if (
    restaurantSettings.breakfastSchedule &&
    dayOfTheWeek < restaurantSettings.breakfastSchedule.length
  ) {
    const endDay = restaurantSettings.breakfastSchedule[dayOfTheWeek]

    if (timeDecimal >= START_DAY && timeDecimal <= Number(endDay)) {
      return true
    }
  }

  return false
}

export function isDinner(restaurantSettings) {
  const CUTOFF = 4.5

  const now = new Date()

  let dayOfTheWeek = now.getDay()

  let timeDecimal = now.getHours() + now.getMinutes() / 60

  if (dayOfTheWeek === 0) {
    dayOfTheWeek = 7
  }

  if (
    restaurantSettings.dinnerSchedule &&
    dayOfTheWeek < restaurantSettings.dinnerSchedule.length
  ) {
    const startDay = restaurantSettings.dinnerSchedule[dayOfTheWeek]

    if (timeDecimal >= startDay || timeDecimal <= CUTOFF) {
      return true
    }
  }

  return false
}
