import React, { useEffect, useState } from 'react'
import StripeCheckout from 'react-stripe-checkout'
import uuidv4 from 'uuid'
import BaseLayout from '../../components/layout/BaseLayout'
import Spinner from '../../components/common/Spinner'
import CartItemsSummary from './CartItemsSummary'
import CustomerDetailsForm from './CustomerDetailsForm'
import { Link } from 'react-router-dom'
import { useCart } from '../../CartContext'
import { toast } from 'react-toastify'
import PhoneNumber from 'awesome-phonenumber'
import DatePicker from 'react-datepicker'
import {
  formatCartItems,
  isDoingDeliveries,
  calculateSubTotal,
  getDistanceFee,
  calculateTaxableSubTotal,
  processOrder,
  generatePrettySchedule,
  trackUser,
} from '../../helpers/cart-helpers'

import 'react-datepicker/dist/react-datepicker.css'

const DOLLAR_TIP = 0.115

const Cart = ({ history }) => {
  const { state, dispatch } = useCart()

  const deliveryMethod = isDoingDeliveries(state.selectedRestaurant.deliverySchedule)
    ? 'Delivery'
    : 'Pickup'

  const [orderDetails, setOrderDetails] = useState({
    deliveryMethod,
    fullName: '',
    phone: '',
    address: '',
    notes: '',
    subtotal: 0,
    discount: 0,
    deliveryFee: 0,
    distanceFee: 0,
    tip: 0,
    tax: 0,
    forLater: false,
    forLaterDate: new Date(),
  })

  const [loading, setLoading] = useState(false)
  const [customTip, toggleCustomTip] = useState(false)
  const [step, setStep] = useState('One')
  const [showOrderLaterDetails, setShowOrderLaterDetails] = useState(false)

  useEffect(() => {
    const formattedCart = formatCartItems(state.items)
    const subtotal = calculateSubTotal(formattedCart)

    const tax = calculateTaxableSubTotal(formattedCart) * state.selectedRestaurant.taxRate

    dispatch({ type: 'SET_FORMATTED_CART', payload: formattedCart })

    if (subtotal !== orderDetails.subtotal) {
      setOrderDetails({ ...orderDetails, subtotal })
    }

    if (tax !== orderDetails.tax) {
      setOrderDetails({ ...orderDetails, tax })
    }
  }, [dispatch, orderDetails, state.items, state.selectedRestaurant.taxRate])

  const canNotContinue = () => {
    if (
      orderDetails.deliveryMethod === 'Pickup' &&
      orderDetails.fullName !== '' &&
      orderDetails.phone !== ''
    ) {
      return false
    }
    if (
      orderDetails.deliveryMethod === 'Delivery' &&
      orderDetails.address &&
      orderDetails.fullName !== '' &&
      orderDetails.phone !== ''
    ) {
      return false
    }
    return true
  }

  const proceedToStepTwo = async () => {
    const phone = new PhoneNumber(orderDetails.phone, 'US').getNumber('significant')
    if (!phone || phone.length !== 10) {
      alert('Invalid phone number format')
      return
    }

    try {
      if (orderDetails.deliveryMethod === 'Delivery') {
        setLoading(true)

        const distanceFee = await getDistanceFee(state.selectedRestaurant, orderDetails.address)

        if (distanceFee === null) {
          toast.error('Sorry, but that address is too far to deliver.', {
            position: toast.POSITION.TOP_CENTER,
          })

          setOrderDetails({
            ...orderDetails,
            address: '',
            deliveryMethod: 'Pickup',
          })
          setLoading(false)
          return
        }

        setOrderDetails({
          ...orderDetails,
          distanceFee,
          deliveryFee: state.selectedRestaurant.deliveryFee,
        })
      }

      setStep('Two')
      setLoading(false)
    } catch (err) {
      console.error(err)
      toast.error('Something went wrong. Please contact us if this continues.', {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  // PLACE ORDER
  const onToken = async (token) => {
    try {
      setLoading(true)

      await processOrder(
        state.items,
        state.selectedRestaurant,
        orderDetails,
        token,
        state.appSettings
      )

      await trackUser(orderDetails, state.selectedRestaurant)

      dispatch({ type: 'CLEAR_ITEMS' })

      history.push({ pathname: '/thank-you', state: { orderDetails } })
    } catch (err) {
      console.error(err)

      toast.error(
        'Something went wrong. Payment did NOT go through. You were NOT charged. Please try again!',
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        }
      )
      setLoading(false)
    }
  }

  return (
    <BaseLayout page="Cart" title="taplocal | Your Cart">
      <section className="block secondary-bg">
        <div className="container mt-3">
          {state.selectedRestaurant && state.selectedRestaurant.isOpen === 0 && (
            <p style={{ color: 'red', textAlign: 'center' }}>
              Sorry, this restaurant is closed at the moment!
            </p>
          )}

          <div className="row mt-4">
            {state.items.length === 0 && (
              <h4 className="mx-auto">
                Your cart is empty. <Link to="/stores">Add some items to your cart!</Link>
              </h4>
            )}

            {state.items.length > 0 && (
              <>
                <div className="col-sm-12 col-md-5">
                  <CartItemsSummary
                    subtotal={orderDetails.subtotal}
                    formattedCart={state.formattedCart}
                    removeItemFromCart={(id) => {
                      dispatch({ type: 'REMOVE_ITEM', payload: id })
                    }}
                  />
                </div>
                <div className="col-sm-12 col-md-7">
                  {loading ? (
                    <Spinner />
                  ) : step === 'One' ? (
                    <>
                      <CustomerDetailsForm
                        doingDeliveries={isDoingDeliveries(
                          state.selectedRestaurant.deliverySchedule
                        )}
                        deliveryOnly={!state.selectedRestaurant.pickup}
                        orderDetails={orderDetails}
                        setOrderDetails={setOrderDetails}
                        deliveryString={generatePrettySchedule(
                          state.selectedRestaurant.deliverySchedule
                        )}
                      />
                      <div className="text-center mt-4">
                        {state.selectedRestaurant.flags &&
                        state.selectedRestaurant.flags.maxLaterHours ? (
                          !showOrderLaterDetails ? (
                            <button
                              className="btn btn-info mb-4"
                              onClick={() => {
                                setShowOrderLaterDetails(true)
                              }}
                            >
                              Place order for later
                            </button>
                          ) : (
                            <>
                              <button
                                className="btn btn-info mb-4"
                                onClick={() => {
                                  setShowOrderLaterDetails(false)
                                  setOrderDetails({ ...orderDetails, forLater: false })
                                }}
                              >
                                Place order for now
                              </button>
                              <p className="mb-1">Select the time:</p>

                              <DatePicker
                                maxDate={
                                  new Date(
                                    Date.now() +
                                      state.selectedRestaurant.flags.maxLaterHours * 3600000
                                  )
                                }
                                className="mb-3"
                                locale="es"
                                showTimeSelect
                                selected={orderDetails.forLaterDate}
                                onChange={(date) =>
                                  setOrderDetails({
                                    ...orderDetails,
                                    forLaterDate: date,
                                    forLater: true,
                                  })
                                }
                              />
                            </>
                          )
                        ) : null}
                        {orderDetails.forLater && (
                          <p>
                            Your order will be scheduled for{' '}
                            {orderDetails.forLaterDate.toLocaleDateString()} at{' '}
                            {orderDetails.forLaterDate.toLocaleTimeString()}
                          </p>
                        )}
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={proceedToStepTwo}
                          disabled={canNotContinue()}
                        >
                          Proceed to payment
                        </button>
                        <br />
                        <br />
                        <small>We never store your credit card number.</small>
                      </div>
                    </>
                  ) : (
                    step === 'Two' && (
                      <div className="text-center">
                        <p
                          onClick={() => {
                            window.location.href = '/cart'
                          }}
                          style={{ color: '#1e8bc3', cursor: 'pointer' }}
                        >
                          &#8592; Change Order Details
                        </p>
                        <br />
                        <p>Would you like to add a tip?</p>
                        <div>
                          <button
                            onClick={() => {
                              toggleCustomTip(false)
                              setOrderDetails({
                                ...orderDetails,
                                tip: orderDetails.subtotal * 0.1,
                              })
                            }}
                            className="btn btn-primary bnt-sm mr-3"
                          >
                            10% Tip
                          </button>
                          <button
                            onClick={() => {
                              toggleCustomTip(false)
                              setOrderDetails({
                                ...orderDetails,
                                tip:
                                  orderDetails.subtotal * DOLLAR_TIP < 250
                                    ? 250
                                    : Math.round((orderDetails.subtotal * DOLLAR_TIP) / 50) * 50,
                              })
                            }}
                            className="btn btn-primary bnt-sm mr-3"
                          >
                            $
                            {orderDetails.subtotal * DOLLAR_TIP < 250
                              ? '2.50'
                              : (
                                  (Math.round((orderDetails.subtotal * DOLLAR_TIP) / 50) * 50) /
                                  100
                                ).toFixed(2)}
                          </button>
                          {!customTip ? (
                            <button
                              onClick={() => toggleCustomTip(true)}
                              className="btn btn-primary bnt-sm mr-3"
                            >
                              Custom Tip
                            </button>
                          ) : (
                            <input
                              min="0"
                              type="number"
                              placeholder="How much?"
                              onChange={(e) => {
                                if (Number(e.target.value) < 0) {
                                  alert('Did we do something wrong? :(')
                                } else {
                                  setOrderDetails({
                                    ...orderDetails,
                                    tip: e.target.value * 100,
                                  })
                                }
                              }}
                            />
                          )}
                        </div>
                        <br />
                        <p>Your Name: {orderDetails.fullName}</p>
                        {orderDetails.deliveryMethod === 'Delivery' && (
                          <p>Deliver to: {orderDetails.address}</p>
                        )}
                        <p>Your Phone: {orderDetails.phone}</p>
                        <br />
                        {orderDetails.forLater && (
                          <p>
                            Your order will be scheduled for{' '}
                            {orderDetails.forLaterDate.toLocaleDateString()} at{' '}
                            {orderDetails.forLaterDate.toLocaleTimeString()}
                          </p>
                        )}
                        <br />
                        <p>Subtotal: ${(orderDetails.subtotal / 100).toFixed(2)}</p>
                        {orderDetails.discount > 0 && <p>Discount: -${orderDetails.discount}</p>}
                        {orderDetails.deliveryMethod === 'Delivery' && (
                          <p>Distance Fee: ${(orderDetails.distanceFee / 100).toFixed(2)}</p>
                        )}
                        {orderDetails.deliveryMethod === 'Delivery' && (
                          <p>Delivery Fee ${(orderDetails.deliveryFee / 100).toFixed(2)}</p>
                        )}
                        <p>Tip: ${(orderDetails.tip / 100).toFixed(2)}</p>
                        <p>Tax: ${(orderDetails.tax / 100).toFixed(2)}</p>
                        <p>
                          Total: $
                          {(
                            (orderDetails.subtotal +
                              orderDetails.deliveryFee +
                              orderDetails.distanceFee +
                              orderDetails.tip +
                              orderDetails.tax -
                              orderDetails.discount * 100) /
                            100
                          ).toFixed(2)}
                        </p>
                        <div className="text-center mt-4">
                          <StripeCheckout
                            token={onToken}
                            amount={
                              orderDetails.subtotal +
                              orderDetails.deliveryFee +
                              orderDetails.distanceFee +
                              orderDetails.tip +
                              orderDetails.tax -
                              orderDetails.discount * 100
                            }
                            name={`Taplocal Order`}
                            description={`Enter your payment details below`}
                            stripeKey="pk_live_q09hs4bnSSLTLpWwvIg8wrzL00psibmuqO"
                            disabled={canNotContinue()}
                            email={`${uuidv4()}-${orderDetails.phone}@taplocal.store`}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </BaseLayout>
  )
}

export default Cart
