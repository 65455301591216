import React from 'react'

import TaplocalLogo from '../assets/images/taplocal.png'

const RegisterTaplocalThankYou = ({ match }) => {
  return (
    <div className="register" style={{ paddingTop: '80px' }}>
      <div className="logo">
        <img className="" src={TaplocalLogo} alt="Logo" />
      </div>
      <div className="content">
        <h1>Thank you for joining taplocal!</h1>
        <p>We are very happy to have you onboard.</p>
      </div>
    </div>
  )
}

export default RegisterTaplocalThankYou
