import React, { useEffect, useCallback, useState } from 'react'
import Modal from 'react-responsive-modal'
import BaseLayout from '../../components/layout/BaseLayout'
import Spinner from '../../components/common/Spinner'
import MenuHeader from './MenuHeader'
import MenuSections from './MenuSections'
import RestaurantOpenStatus from './RestaurantOpenStatus'
import MenuItemList from './MenuItemList'
import AddItemModal from './AddItemModal'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useCart } from '../../CartContext'
import { getStore } from '../../helpers/restaurant-helpers'

const RestaurantMenu = ({ match }) => {
  const { state, dispatch } = useCart()
  const [loading, setLoading] = useState(true)
  const [selectedMenuSection, setSelectedMenuSection] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const { selectedRestaurant } = state

  const loadRestaurant = useCallback(() => {
    getStore(match.params.restaurantId)
      .then((restaurant) => {
        dispatch({ type: 'SET_SELECTED_RESTAURANT', payload: restaurant })
        dispatch({ type: 'CLEAR_ITEMS' })
        localStorage.setItem('selectedStore', restaurant.nickname)
        localStorage.setItem('selected-section', restaurant.inventory[0].name)
        setSelectedMenuSection(restaurant.inventory[0].name)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        toast.error('An error occured while loading this restaurant!', {
          position: toast.POSITION.TOP_CENTER,
        })
        setLoading(false)
      })
  }, [dispatch, match.params.restaurantId])

  useEffect(() => {
    if (selectedRestaurant) {
      if (selectedRestaurant.nickname !== match.params.restaurantId) {
        loadRestaurant()
      } else {
        setLoading(false)
        setSelectedMenuSection(selectedRestaurant.inventory[0].name)
      }
    } else {
      loadRestaurant()
    }
  }, [loadRestaurant, match.params.restaurantId, selectedRestaurant])

  useEffect(() => {
    const rememberedSection = localStorage.getItem('selected-section')
    setSelectedMenuSection(rememberedSection)
  }, [])

  const openModal = (item) => {
    setSelectedItem(item)
    setModalOpen(true)
  }

  const addItemToCart = (payload) => {
    dispatch({ type: 'ADD_ITEM', payload })
    setModalOpen(false)
    toast.success(`Added ${payload.name.split('|')[0]} to cart!`, {
      position: toast.POSITION.TOP_CENTER,
    })
  }

  return (
    <BaseLayout
      page={`${loading ? '' : selectedRestaurant.name}`}
      title={`taplocal | ${loading ? '' : selectedRestaurant.name}`}
    >
      {loading ? (
        <div style={{ marginTop: '300px' }}>
          <Spinner />
        </div>
      ) : (
        <>
          {modalOpen && (
            <Modal
              open={modalOpen}
              onClose={() => {
                setSelectedItem(null)
                setModalOpen(false)
              }}
              center
            >
              <AddItemModal
                item={selectedItem}
                addItemToCart={addItemToCart}
                restaurant={selectedRestaurant}
              />
            </Modal>
          )}
          <section className="block pb-details">
            <div className="details-topbar text-center">
              <MenuHeader restaurant={selectedRestaurant} />
              <MenuSections
                restaurant={selectedRestaurant}
                selectedMenuSection={selectedMenuSection}
                setSelectedMenuSection={setSelectedMenuSection}
              />
              {state.items.length > 0 && (
                <div className="mt-4">
                  <Link to="/cart">
                    <button className="btn btn-primary">
                      Go To Checkout ({state.items.length})
                    </button>
                  </Link>
                </div>
              )}
            </div>
            <RestaurantOpenStatus restaurant={selectedRestaurant} />
            <MenuItemList
              restaurant={selectedRestaurant}
              selectedMenuSection={selectedMenuSection}
              openModal={openModal}
            />
          </section>
        </>
      )}
    </BaseLayout>
  )
}

export default RestaurantMenu
