import React, { useState } from 'react'
import Picker from './Picker'
import TableOptions from './TableOptions'
import FallBackImage from '../../../assets/images/main-logo.png'

const AddItemModal = ({ item, addItemToCart, restaurant }) => {
  const [pickerValue, setPickerValue] = useState(
    item && item.picker.name
      ? item.picker.name[0] + '=' + (item.picker.price && item.picker.price[0])
      : 'None'
  )

  const [tableValues, setTableValues] = useState({})

  const [specialInstructions, setSpecialInstructions] = useState('')

  if (!item) {
    return <p className="p-5">Loading...</p>
  }

  return (
    <div className="container p-4">
      <div className="row">
        <div className="col-md-12 list-content">
          <h3 className="sub-title">{item.name.split('|')[0]}</h3>
          <p>{item.description}</p>
          <img
            className="food-item"
            src={`https://firebasestorage.googleapis.com/v0/b/tap-restaurant-1.appspot.com/o/images%2F${restaurant.nickname}%2F${item.image}?alt=media&token=52693565-03b1-45b9-97ee-5bb70a0455d5`}
            alt={item.name}
            onError={(e) => {
              e.target.onerror = null
              e.target.src = FallBackImage
            }}
          />
          {Number(item.price) === 0 ? (
            <p>Price: Your options</p>
          ) : (
            <span className="price">${(Number(item.price) / 100).toFixed(2)}</span>
          )}
          <div className="row">
            {item.picker && item.picker.name && (
              <Picker item={item} pickerValue={pickerValue} setPickerValue={setPickerValue} />
            )}
          </div>
          <div className="row">
            <TableOptions item={item} tableValues={tableValues} setTableValues={setTableValues} />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <textarea
            value={specialInstructions}
            onChange={(e) => setSpecialInstructions(e.target.value)}
            placeholder="Special instructions"
            className="form-control"
            rows="2"
          ></textarea>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <button
            className="btn btn-primary"
            onClick={() => {
              addItemToCart({
                name: item.name,
                price: item.price,
                pickerValue,
                tableValues,
                specialInstructions,
                isTaxFree: item.flags.isTaxFree,
              })
            }}
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddItemModal
