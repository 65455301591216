import React from 'react'
import { getTodaysSchedule } from '../../helpers/restaurant-helpers'

const MenuHeader = ({ restaurant }) => {
  console.log(restaurant)
  return (
    <div className="topbar-row details-topbar-top primary-bg-ex">
      <div className="container">
        <h4 className="text-uppercase white mx-0 mb-4">
          {restaurant.name} - {restaurant.address}
        </h4>
        <p className="m-0" style={{ fontSize: '.9rem', color: 'white' }}>
          Schedule Today: {getTodaysSchedule(restaurant, 'schedule')}
        </p>
        {restaurant.deliverySchedule && (
          <p className="m-0" style={{ fontSize: '.9rem', color: 'white' }}>
            Delivery Schedule: {getTodaysSchedule(restaurant, 'deliverySchedule')}
          </p>
        )}
      </div>
    </div>
  )
}

export default MenuHeader
