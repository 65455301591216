import React from 'react'
import MenuItemListCard from './MenuItemListCard'
import { toast } from 'react-toastify'

const MenuItemList = ({ restaurant, selectedMenuSection, openModal }) => {
  const onSelectItem = (item) => {
    if (restaurant.onVacation) {
      toast.error(`Store is closed at the moment!`, {
        position: toast.POSITION.TOP_CENTER,
      })
    } else if (restaurant.isOpen === 0) {
      toast.error(`Store is closed at the moment!`, {
        position: toast.POSITION.TOP_CENTER,
      })
    } else {
      openModal(item)
    }
  }

  let section = restaurant.inventory.find((section) => section.name === selectedMenuSection)

  if (!section) {
    section = restaurant.inventory[0]
  }
  return (
    <>
      {section.description && <p className="text-center mt-4">{section.description}</p>}
      <div className="pd-details-main pb-box">
        <div className="container clearfix">
          <div className="row">
            {section.items
              .sort((a, b) => {
                return a.orderWeight - b.orderWeight
              })
              .map((item, index) => {
                return (
                  <MenuItemListCard
                    key={index}
                    restaurant={restaurant}
                    item={item}
                    onSelectItem={onSelectItem}
                  />
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export default MenuItemList
