import React from 'react'
import Spinner from '../../components/common/Spinner'
import HeroBg from '../../assets/images/broadway.jpg'

const RestaurantSearchSection = ({
  submitSearch,
  searching,
  searchLocation,
  setSearchLocation,
}) => {
  return (
    <section className="block hero-section d-flex align-items-center flex-wrap expand-bg bg-overlay bg-overlay-dark opacity-7">
      <div className="container clearfix">
        <div className="row">
          <div className="offset-lg-1 col-lg-10 col-md-12">
            <h2 className="text-center white">
              <strong>
                Welcome to taplocal! <br />
                We deliver with no service fees!
              </strong>
            </h2>
            <div className="form mt-5 hero-form">
              <div className="row">
                <div className="offset-lg-2 col-lg-8 offset-md-1 col-md-10">
                  <div className="input-wrap position-relative">
                    {searching ? (
                      <Spinner />
                    ) : (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault()
                          submitSearch(searchLocation)
                        }}
                      >
                        <input
                          name="searchLocation"
                          value={searchLocation}
                          type="search"
                          className="input input-radial has-ab-button bg-transparent-white"
                          placeholder="Enter a location..."
                          onChange={(e) => setSearchLocation(e.target.value)}
                          required
                        />

                        <button type="submit" className="btn btn-primary btn-lg btn-radial btn-abs">
                          <div>Search</div>
                        </button>
                      </form>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-4 text-center">
                <br />
                <div className="mb-2 w-100 text-light">
                  <p>Or pick an area:</p>
                </div>
                <div className="mb-2 w-100">
                  <span
                    className="text-light cursor-hover hover-dark"
                    onClick={() => submitSearch('Madison, NJ')}
                  >
                    Madison, NJ
                  </span>
                </div>
                <div className="mb-2 w-100">
                  <span
                    className="text-light cursor-hover hover-dark"
                    onClick={() => submitSearch('Morristown, NJ')}
                  >
                    Morristown, NJ
                  </span>
                </div>
                <div className="mb-2 w-100">
                  <span
                    className="text-light cursor-hover hover-dark"
                    onClick={() => submitSearch('Summit, NJ')}
                  >
                    Summit, NJ
                  </span>
                </div>
                <div className="mb-2 w-100">
                  <span
                    className="text-light cursor-hover hover-dark"
                    onClick={() => submitSearch('Westfield, NJ')}
                  >
                    Westfield, NJ
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg" id="234567890" style={{ backgroundImage: `url(${HeroBg})` }}></div>
    </section>
  )
}

export default RestaurantSearchSection
